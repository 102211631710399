export const calculateTeamHitting = (team) => {
  let score = 0;

  // hitting +
  score += hittingPlus(team);

  // hitting -
  score += hittingNegative(team);

  return score;
}

export const calculateTeamPitching = (team) => {
  let score = 0;

  // pitching +
  score += pitchingPlus(team)
  score += pitchingNegative(team)

  // pitching -

  return score;
}

const pitchingPlus = (team) => {
  let score = 0;

  // batting average against
  if (team.avg <= ".200") {
    score += 100
  } else if (team.avg <= '.220') {
    score += 80
  } else if (team.avg <= '.230') {
    score += 50
  } else if (team.avg <= '.235') {
    score += 45
  } else if (team.avg <= '.240') {
    score += 40
  } else if (team.avg <= '.245') {
    score += 35
  } else if (team.avg <= '.250') {
    score += 30
  } else if (team.avg <= '.255') {
    score += 25
  } else {
    score += 20
  }

  // shutouts * 5 points
  score += (team.shutouts * 5)

  // caught stealing
  score += team.caughtStealing

  // K per 9in, range: 7-10
  if (team.strikeoutsPer9Inn >= '10.00') {
    score += 100
  } else if (team.strikeoutsPer9Inn >= '9.50') {
    score += 90
  } else if (team.strikeoutsPer9Inn >= '9.00') {
    score += 80
  } else if (team.strikeoutsPer9Inn >= '8.75') {
    score += 75
  } else if (team.strikeoutsPer9Inn >= '8.50') {
    score += 70
  } else if (team.strikeoutsPer9Inn >= '8.25') {
    score += 65
  } else if (team.strikeoutsPer9Inn >= '8.00') {
    score += 50
  } else if (team.strikeoutsPer9Inn >= '7.75') {
    score += 40
  } else if (team.strikeoutsPer9Inn >= '7.50') {
    score += 35
  } else if (team.strikeoutsPer9Inn >= '7.25') {
    score += 20
  } else if (team.strikeoutsPer9Inn >= '7.00') {
    score += 15
  } else {
    score += 0
  }

  // win percent
  let wp = Math.round(parseFloat(team.winPercentage) * 100)
  score += wp;

  //whip

  if (team.whip <= '1.00') {
    score += 50
  } else if (team.whip <= '1.10') {
    score += 46
  } else if (team.whip <= '1.05') {
    score += 42
  } else if (team.whip <= '1.10') {
    score += 38
  } else if (team.whip <= '1.15') {
    score += 36
  } else if (team.whip <= '1.20') {
    score += 34
  } else if (team.whip <= '1.25') {
    score += 32
  } else if (team.whip <= '1.30') {
    score += 25
  } else if (team.whip <= '1.35') {
    score += 23
  } else if (team.whip <= '1.40') {
    score += 21
  } else if (team.whip <= '1.45') {
    score += 18
  } else {
    score += 15
  }

  return score;
}

const pitchingNegative = (team) => {
  let score = 0;

  // balks
  score -= team.balks

  score -= team.homeRuns

  score -= team.catchersInterference

  // K-BB ratio, 2024 had between .200 and .400
  if (team.strikeoutWalkRatio <= '.200') {
    score -= 35
  } else if (team.strikeoutWalkRatio <= '.250') {
    score -= 25
  } else if (team.strikeoutWalkRatio <= '.300') {
    score -= 20
  } else if (team.strikeoutWalkRatio <= '.350') {
    score -= 10
  } else if (team.strikeoutWalkRatio <= '.400') {
    score -= 5
  } else {
    score -= 0
  }

  //ERA range 3.5 - 5
  if (team.era >= '5.00') {
    score -= 80
  } else if (team.era >= '4.50') {
    score -= 75
  } else if (team.era >= '4.25') {
    score -= 65
  } else if (team.era >= '4.00') {
    score -= 30
  } else if (team.era >= '3.95') {
    score -= 28
  } else if (team.era >= '3.90') {
    score -= 25
  } else if (team.era >= '3.85') {
    score -= 20
  } else if (team.era >= '3.80') {
    score -= 18
  } else if (team.era >= '3.75') {
    score -= 16
  } else if (team.era >= '3.70') {
    score -= 12
  } else if (team.era >= '3.65') {
    score -= 8
  } else if (team.era >= '3.60') {
    score -= 6
  } else if (team.era >= '3.55') {
    score -= 3
  } else {
    score -= 0
  }

  // walks per 9in, range 4.25-2.00
  if (team.walksPer9Inn >= '4.25') {
    score -= 25
  } else if (team.walksPer9Inn >= '3.50') {
    score -= 20
  } else if (team.walksPer9Inn >= '3.00') {
    score -= 15
  } else if (team.walksPer9Inn >= '2.50') {
    score -= 10
  } else {
    score -= 5
  }

  return score;
}

// Positive Hitting numbers, these things will be positives that help boost the score up
const hittingPlus = (team) => {
  let score = 0;

  // home runs per game
  score += ((team.homeRuns / team.gamesPlayed) * 15);

  // batting average rankings
  if (team.avg >= .285) {
    score += 30
  } else if (team.avg >= .275) {
    score += 25
  } else if (team.avg >= .265) {
    score += 20
  } else if (team.avg >= .260) {
    score += 18
  } else if (team.avg >= .250) {
    score += 16
  } else if (team.avg >= .240) {
    score += 14
  } else if (team.avg >= .230) {
    score += 12
  } else {
    score += 6
  }

  // OBP rankings
  if (team.ops >= .850) {
    score += 25
  } else if (team.ops >= .800) {
    score += 20
  } else if (team.ops >= .750) {
    score += 15
  } else if (team.ops >= .700) {
    score += 10
  } else if ((team.ops >= .699)) {
    score += 5
  }

  // stolen base %
  if (team.stolenBasePercentage >= .900) {
    score += 25
  } else if (team.stolenBasePercentage >= 0.850) {
    score += 20
  } else if (team.stolenBasePercentage >= 0.800) {
    score += 15
  } else if (team.stolenBasePercentage >= 0.750) {
    score += 10
  } else if (team.stolenBasePercentage >= 0.700) {
    score += 5
  }

  // add in the total stolen bases
  score += team.stolenBases

  // walks per game
  if (team.baseOnBalls / team.gamesPlayed >= 6) {
    score += 25
  } else if (team.baseOnBalls / team.gamesPlayed >= 5) {
    score += 20
  } else if (team.baseOnBalls / team.gamesPlayed >= 4) {
    score += 15
  } else if (team.baseOnBalls / team.gamesPlayed >= 3) {
    score += 10
  } else if (team.baseOnBalls / team.gamesPlayed >= 2) {
    score += 5
  } else if (team.baseOnBalls / team.gamesPlayed >= 1) {
    score += 2
  } else {
    score += 1
  }

  // walks + ibb per plate appearance (range is 0.7 to 0.12)
  let wibbppa = (((team.baseOnBalls + team.intentionalWalks) / team.plateAppearances)).toFixed(3);
  if (wibbppa >= 0.12) {
    score += 30
  } else if (wibbppa >= 0.11) {
    score += 25
  } else if (wibbppa >= 0.10) {
    score += 20
  } else if (wibbppa >= 0.90) {
    score += 15
  } else if (wibbppa >= 0.80) {
    score += 10
  } else if (wibbppa >= 0.70) {
    score += 5
  }

  // number of pitches per at bat
  score += (team.numberOfPitches / team.atBats);

  return score;
}

// Negative Hitting numbers, these things will be negatives that detract from the score
const hittingNegative = (team) => {
  let score = 0;

  // grounded into double play per game
  let gidppg = team.groundIntoDoublePlay / team.gamesPlayed;
  if (gidppg >= 0.9) {
    score -= 30
  } else if (gidppg >= 0.85) {
    score -= 27
  } else if (gidppg >= 0.80) {
    score -= 24
  } else if (gidppg >= 0.75) {
    score -= 21
  } else if (gidppg >= 0.70) {
    score -= 18
  } else if (gidppg >= 0.65) {
    score -= 15
  } else if (gidppg >= 0.60) {
    score -= 12
  } else if (gidppg >= 0.55) {
    score -= 9
  } else if (gidppg >= 0.50) {
    score -= 6
  } else if (gidppg >= 0.45) {
    score -= 3
  }

  // left on base per game
  score -= (team.leftOnBase / team.gamesPlayed);

  //at bats per home run
  // score -= team.atBatsPerHomeRun;

  score -= (team.strikeOuts / team.gamesPlayed);

  return score;
}

