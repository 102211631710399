// import NewsFeed from "../../components/NewsFeed/NewsFeed";

const Homepage = (props) => {
  return (
    <>
      <div>
        <h3>Welcome</h3>
        <br />
        <div>
          Updates:
          <ul>
            <li>Introducing SABRScore. The new power ranking score using a new algorithm. Ready to go for 2025 using both Hitting and Pitching stats</li>
            <li>Standings page - updated to 2025, enhanced playoff tracking</li>
            <li>Both the SABRScore and Standings now have additional customization options, such as looking back at prior years; current year standings may look off for the first week of season</li>
            <li>Play Ball - Simulations are back, but only for legacy teams</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Homepage;